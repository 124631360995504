<template>
   <div class="page-bar">
        <ul style="display: inline-block">
            <li>
              <a id="page-bar-a" v-on:click="pageClick(pageNow-1)">{{text}}</a>
            </li>
            <li v-if="indexs.length>0">
                <span v-for="(index,indeslength) in indexs"  v-bind:class="pageNow == index?'active':''" :key="indeslength">
                <a id="page-bar-a" v-on:click="pageClick(index)">{{ index }}</a>
                </span>
            </li>
            <li v-if="pages>4" v-on:click="pageClick(pageNowResult?pageNow-1:pageNow+1)">
                <a id="page-bar-a">...</a>
            </li>
            <li v-if="pages>=7">
                <span v-bind:class="pageNow == pages-2?'active':''">
                    <a id="page-bar-a" v-on:click="pageClick(pages-2)">{{pages-2}}</a>
                </span>  
            </li>
            <li v-if="pages>=6">
                <span v-bind:class="pageNow == pages-1?'active':''">
                    <a id="page-bar-a" v-on:click="pageClick(pages-1)">{{pages-1}}</a>
                </span>  
            </li>
            <li v-if="pages>=5">
                <span v-bind:class="pageNow == pages?'active':''">
                    <a id="page-bar-a" v-on:click="pageClick(pages)">{{pages}}</a>
                </span>  
            </li>
            <li>
                <a id="page-bar-a" v-on:click="pageClick(pageNow+1)">></a>
            </li>
        </ul>
  </div>
</template>

<script>
export default {
  name: 'page',
  props:["pagenumber","pages","callback"], //接收参数
  data (props) {
    return {
            pageNow:props.pagenumber,
            num:props.pagenumber,
            text:'<',
            pageNowResult:false
        }
    },
    mounted:function(){
       
    },
    //计算属性
    computed: {
        indexs: function(){
            var left = 1;
            var right = this.pages;
            var ar = [];
            if(this.pages>= 5){
                var result = (this.pageNow > 2 && this.pageNow <=this.pages-2)
                if(this.pages==6){
                    result = (this.pageNow > 2 && this.pageNow <=this.pages-3)
                }
                if(this.pages>=7){
                    result = (this.pageNow > 2 && this.pageNow <=this.pages-4)
                }
                if(result){
                    left = this.pageNow - 1;
                    right = this.pageNow + 1
                }else{
                    if(this.pages==5){
                        this.pageNowResult=this.pageNow<=3||this.pageNow==this.pages
                    }
                    if(this.pages==6){
                        this.pageNowResult=this.pageNow<=3||this.pageNow>=this.pages-1
                    }
                    if(this.pages>=7){
                        this.pageNowResult=this.pageNow<=3||this.pageNow>=this.pages-2
                    }
                    if(this.pageNowResult){
                        left = 1;
                        right = 3
                    }else{
                        if(this.pages==5){
                            right = this.pages-1;
                            left = this.pages -3
                        }else if(this.pages==6){
                            right = this.pages-2;
                            left = this.pages -4
                        }else{
                            right = this.pages-3;
                            left = this.pages -5
                        }
                    }
                }
            }
            while (left <= right){
                ar.push(left);
                left ++
            }
            return ar
        }
    },
   //方法处理
    methods: {
        pageClick:function(e){
            if(e==""||e==0){
                return;
            }
            e=Number(e);
            if(e>this.pages){
                e=this.pages;
            }
            if(e != this.pagenumber||e==this.num){
                this.pageNow =e;
            }
            this.callback(e)
        }
    },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style   scoped>
/*--------------------------分页页面 公共的start-------------------------*/
.page-bar {
    width: 100%;
    height: 24px;
    margin-top: 24px;
    
}
#page-bar-a{
    color: rgba(102, 102, 102, 1);
    text-align: center;
}
.page-bar ul {
    display: inline-block;
}
.page-bar ul li{
    list-style: none;
    float: left;
}

.page-bar a{
    text-decoration: none;
    float: left;
    font-family: "HarmonyOS Sans SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
    width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 6px;
    background: transparent;
    margin-right: 6px;
}
/* .page-bar a:hover{
    background-color: #5B4FA9;
} */
.page-bar a.banclick{
    cursor:not-allowed;
}

.page-bar .active #page-bar-a{
    color: rgba(0, 0, 0, 1) !important;
    cursor: default;
    background: rgba(201, 250, 91, 1);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    font-family: Bai Jamjuree;
}
.page-bar i{
    font-style:normal;
    color: #d44950;
    margin: 0px 4px;
    font-size: 12px;
}
.page-bar-click{
    display: none;
}

@media screen and (max-width: 460px){
    
}
</style>
