<template>
    <div class="main-box">
      <h5header :name="$t('lockrelease.desc20')" :right="false" />
     
      <div class="main-box-auto">
       <div class="info-all-box">
        <div class="info-card-box left-box">
            <div class="card-list-box">
                <div class="title">{{ $t('lockrelease.desc1') }}</div>
                <div class="value" style="color: rgba(201, 250, 91, 1);">{{ daotReleaseInfo.coinName }}</div>
            </div>
            <div class="card-list-box">
                <div class="title">{{ $t('lockrelease.desc2') }}</div>
                <div class="value">{{ Number(daotReleaseInfo.total).toLocaleString('en-US') }}</div>
            </div>
            <div class="card-list-box">
                <div class="title">{{ $t('lockrelease.desc3') }}</div>
                <div class="value">{{ daotReleaseInfo.coinType }}</div>
            </div>
            <div class="card-list-box">
                <div class="title">{{ $t('lockrelease.desc4') }}</div>
                <div class="value">
                    {{ omitshow(daotReleaseInfo.contractAddress,6,5) }}
                    <img src="../../public/images/new/copy1.png" @click="copy($event, daotReleaseInfo.contractAddress)" style="height: 16px; cursor: pointer;margin-left: 8px;">
                </div>
            </div>
        </div>
        <div class="info-card-box right-box">
            <div class="card-title">{{ $t('lockrelease.desc5') }}</div>
            <div class="card-list-all">
                <div class="card-box">
                    <div class="card-text-list">
                        <div class="text"> <img src="../../public/images/daot/light-mark.svg" style="width: 10px;">{{ $t('lockrelease.desc6') }}</div>
                        <div class="value">{{ Number(releaseInfo.buyAmount).toLocaleString('en-US') }}</div>
                    </div>
                    <div class="card-text-list">
                        <div class="text"><img src="../../public/images/daot/light-mark.svg" style="width: 10px;">{{ $t('lockrelease.desc7') }}</div>
                        <div class="value">{{ Number(releaseInfo.remainingLockAmount).toLocaleString('en-US') }}</div>
                    </div>
                    <div class="card-text-list">
                        <div class="text"><img src="../../public/images/daot/light-mark.svg" style="width: 10px;">{{ $t('lockrelease.desc8') }}</div>
                        <div class="value">{{ Number(releaseInfo.tookAmount).toLocaleString('en-US') }}</div>
                    </div>
                    <div class="card-text-list">
                        <div class="text"><img src="../../public/images/daot/light-mark.svg" style="width: 10px;">{{ $t('lockrelease.desc9') }}</div>
                        <div class="value">{{ Number(releaseInfo.unTakeAmount).toLocaleString('en-US') }}</div>
                    </div>
                </div>
                <div class="card-btn" :class="releaseInfo.unTakeAmount<=0?'card-btn-not':''" @click="releaseInfo.unTakeAmount>0?receive():''">{{ $t('lockrelease.desc10') }}</div>
            </div>
        </div>
       </div>
       <div class="notice-box">
            <div class="notice-logo">
                <img src="../../public/images/daot/notice-logo.png" style="width: 100px;">
            </div>
            <div class="notice-des">{{ $t('lockrelease.desc11') }}</div>
       </div>
       <div class="record-box">
            <div class="record-title">{{ $t('lockrelease.desc12') }}</div>
            <div class="record-table">
                <div class="table-header">
                    <div class="column1">{{ $t('lockrelease.desc13') }}</div>
                    <div class="column2">{{ $t('lockrelease.desc14') }}</div>
                    <div class="column3">{{ $t('lockrelease.desc15') }}</div>
                    <div class="column4">{{ $t('lockrelease.desc16') }}</div>
                </div>
                <div class="table-row">
                    <div class="row-list" v-for="item,index in recordData" :key="index">
                        <div class="column1">{{ item.id }}</div>
                        <div class="column2">{{ item.releaseTime }}</div>
                        <div class="column3">{{ Number(item.releaseAmount).toLocaleString('en-US') }}</div>
                        <div class="column4" :style="{color:item.takeState==0?'rgba(201, 250, 91, 1)':'rgba(255, 255, 255, 1)'}">{{ item.takeState==0?$t('lockrelease.desc17'):$t('lockrelease.desc18') }}</div>
                    </div>
                </div>
            </div>
            <page v-if="pageModel.pageges>1" :pagenumber="pageModel.page" :pages="pageModel.pageges" :callback="pageBack"></page>
       </div>
      </div>
    </div>
</template>
  
  <script>
  import h5header from "@/componets/h5header.vue";
  import clipboard from "@/utils/libs/clipboard";
  import page from "@/componets/page.vue";
  export default {
    components: {
      h5header,
      clipboard,
      page,
    },
    data() {
      return {
        pageModel:{
            page:1, //当前页
            pageges:0, //总页数
            pageSize: 10, //一页的条数
        },
        recordData:[],
        releaseInfo:{
            buyAmount:'', //认购数量
            remainingLockAmount:'',//剩余锁仓数量
            tookAmount:'',//已领取数量
            unTakeAmount:''//待领取数量
        },
        daotReleaseInfo:{
            total:'',//发行数量
            coinName:'',//代币名称
            coinType:'', //代币类型
            contractAddress:'' //合约地址
        }
        
      };
    },
    mounted() {
        this.getRecordList();
      
    },
    methods: {

        //复制操作
        copy(e, text) {
            clipboard(e, text);
            this.$message.success(this.$t("planet.desc61"));
        }, 
        //分页回调
        pageBack(t){
            this.pageModel.page=t;
            this.getRecordList();
        },
        /*
        * 地址省略显示 
        * @param value {string} 数据
        * @param start {number} 前面显示几位
        * @param end {number} 后面面显示几位
        * 
        */
        omitshow(value, start, end) {
            if (typeof value == 'string' && typeof start == 'number' && typeof end == 'number') {
                var str = value.substring(0, start) + "..." + value.substring(value.length - end)
                return str
            }
        },
        //分页列表数据
        getRecordList() {
            this.$post(this.URL.daot.ReleaseList,{page:this.pageModel.page,pageSize:this.pageModel.pageSize}).then((res) => {
                if (res.code == 0) {
                    this.recordData = res.data.list;
                    this.releaseInfo = res.data.myReleaseInfo;
                    this.daotReleaseInfo = res.data.daotReleaseInfo;
                    this.pageModel.pageges = res.data.page.totalPage
                }
            });
        },
        //一键领取
        receive(){
            this.$post(this.URL.daot.oneKeyTake,{}).then((res) => {
                if (res.code == 0) {
                    if(res.data){
                        this.getRecordList();
                        this.$message.success($t('lockrelease.desc19'));
                    }else{
                        this.$message.success(res.message);
                    }
                }
            }); 
        } 
    },
  };
  </script>
  
<style scoped>
.main-box{
    padding: 96px 0 80px;
    position: relative;
    width: 100%;
    height: 100%;
}
.main-box-auto{
    width:calc(100% - 32px);
    max-width: 1240px;
    margin: 0 auto;
}
.info-all-box{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 32px;
}
.left-box{
    max-width:526px;
    width: 42%;
    border-radius: 12px 0px 0px 12px;
    padding: 15px 32px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.right-box{
    width:calc(58% - 4px);
    border-radius:0px, 12px, 12px, 0px;
    padding: 32.5px;
    box-sizing: border-box;
}
.info-card-box{
    height: 162px;
    background: rgba(24, 24, 24, 1);
}
.card-list-box{
    display: flex;
    font-family: Bai Jamjuree;
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: rgba(154, 154, 154, 1)

}
.card-list-box .value{
    color: rgba(255, 255, 255, 1);
    margin-left: 30px;
    display: flex;
}
.card-title{
    font-family: Bai Jamjuree;
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
}
.card-list-all{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25.5px;
}
.card-box{
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 48px;
}
.card-text-list{
    color: rgba(255, 255, 255, 1);
}
.card-text-list .text{
    font-size: 12px;
}
.card-text-list .value{
    font-size: 18px;
    margin-top: 13px;
}
.card-btn{
    width: 174px;
    height: 48px;
    background: url("../../public/images/new/btnbg28.png") center no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
    color: #000000;
    text-align: center;
    line-height: 48px;
    font-family: Bai Jamjuree;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
}
.card-btn-not{
    background: url("../../public/images/new/btnbg24.png") center no-repeat;
    background-size: 100% 100%;
    cursor: not-allowed;
}
.notice-box{
    margin-top: 40px;
}
.notice-des{
    font-family: PingFang SC;
    font-size: 18px;
    font-weight: 400;
    line-height: 25.2px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
}

.record-box{
    margin-top: 60px;
}
.record-title{
    font-family: Bai Jamjuree;
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    margin-bottom: 20px;
    color: rgba(255, 255, 255, 1);
}
.table-header{
    display: flex;
    width:100%;
    height: 52px;
    line-height: 52px;
    background: rgba(24, 24, 24, 1);
    font-family: PingFang SC;
    font-size: 13px;
    font-weight: 400;
    text-align: left;
    color: rgba(255, 255, 255, 0.6);
    padding-right: 60px;
    padding-left: 24px;
    box-sizing: border-box;
}
.table-row{
    width: 100%;
}
.row-list{
    display: flex;
    font-family: Bai Jamjuree;
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
    text-align: left;
    height: 56px;
    line-height: 56px;
    border-bottom: 1px solid rgba(26, 26, 26, 1);
    color: rgba(255, 255, 255, 0.8);
    padding-right: 60px;
    padding-left: 24px;
    box-sizing: border-box;

}
.column1{
    width: 15%;
}
.column2{
    width: 35%;
}
.column3{
    width: 35%;
    text-align: right;
}
.column4{
    text-align:right;
    width: 15%;
}
  
@media (max-width: 1180px) {
    .left-box{
        width: 35%;
    }
    .right-box{
        width:calc(65% - 4px);
    }
    
}
@media (max-width: 1024px) {
    .info-all-box{
        flex-direction: column;
        margin-top: 0px;
    }
    .left-box{
        width: 100%;
        max-width: 100%;
    }
    .right-box{
        width: 100%;
        margin-top: 10px;
    }
}
@media (max-width: 767px) {
    .main-box{
        padding: 56px 0 80px;
    }
    .info-card-box{
        height: fit-content;
    }
    .card-list-all{
        flex-direction: column;
    }
    .card-box{
        gap: 0px;
        width: 100%;
        flex-wrap: wrap;
    }
    .card-text-list{
        width: 25%;
    }
    .card-btn{
        width: 100%;
        margin-top: 20px;
    }
    .notice-logo{
        width: 100px;
        margin: 0 auto;
    }
    .left-box{
        padding: 16px;
    }
    .right-box{
       padding: 16px;
    }
    .table-header{
        padding-right: 20px;
        padding-left: 13px;
    }
    .row-list{
        padding-right: 20px;
        padding-left: 13px;
    }
    .column2{
        width: 60%;
    }
    .column3{
        width: 25%;
    }
    .column4{
        width: 40%;
    }
    .card-list-box{
        font-size: 13px;
        line-height: 13px;
    }
    .card-title{
        font-size: 18px;
    }
}
@media (max-width: 550px) {
   
    .card-text-list{
        width: 50%;
        margin-bottom:20px;
    }
    .card-btn{
        width: 100%;
        margin-top:0px;
    }
}
</style>